exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hcp-billing-coding-index-js": () => import("./../../../src/pages/hcp/billing-coding/index.js" /* webpackChunkName: "component---src-pages-hcp-billing-coding-index-js" */),
  "component---src-pages-hcp-bone-age-to-chronological-age-index-js": () => import("./../../../src/pages/hcp/bone-age-to-chronological-age/index.js" /* webpackChunkName: "component---src-pages-hcp-bone-age-to-chronological-age-index-js" */),
  "component---src-pages-hcp-caregiver-resources-index-js": () => import("./../../../src/pages/hcp/caregiver-resources/index.js" /* webpackChunkName: "component---src-pages-hcp-caregiver-resources-index-js" */),
  "component---src-pages-hcp-downloads-office-support-index-js": () => import("./../../../src/pages/hcp/downloads-office-support/index.js" /* webpackChunkName: "component---src-pages-hcp-downloads-office-support-index-js" */),
  "component---src-pages-hcp-gonadal-hormone-clinical-data-index-js": () => import("./../../../src/pages/hcp/gonadal-hormone-clinical-data/index.js" /* webpackChunkName: "component---src-pages-hcp-gonadal-hormone-clinical-data-index-js" */),
  "component---src-pages-hcp-index-js": () => import("./../../../src/pages/hcp/index.js" /* webpackChunkName: "component---src-pages-hcp-index-js" */),
  "component---src-pages-hcp-insertion-removal-index-js": () => import("./../../../src/pages/hcp/insertion-removal/index.js" /* webpackChunkName: "component---src-pages-hcp-insertion-removal-index-js" */),
  "component---src-pages-hcp-lh-clinical-data-index-js": () => import("./../../../src/pages/hcp/lh-clinical-data/index.js" /* webpackChunkName: "component---src-pages-hcp-lh-clinical-data-index-js" */),
  "component---src-pages-hcp-once-yearly-dosing-index-js": () => import("./../../../src/pages/hcp/once-yearly-dosing/index.js" /* webpackChunkName: "component---src-pages-hcp-once-yearly-dosing-index-js" */),
  "component---src-pages-hcp-post-procedure-care-index-js": () => import("./../../../src/pages/hcp/post-procedure-care/index.js" /* webpackChunkName: "component---src-pages-hcp-post-procedure-care-index-js" */),
  "component---src-pages-hcp-procedure-index-js": () => import("./../../../src/pages/hcp/procedure/index.js" /* webpackChunkName: "component---src-pages-hcp-procedure-index-js" */),
  "component---src-pages-hcp-procedure-video-index-js": () => import("./../../../src/pages/hcp/procedure-video/index.js" /* webpackChunkName: "component---src-pages-hcp-procedure-video-index-js" */),
  "component---src-pages-hcp-request-a-sales-rep-index-js": () => import("./../../../src/pages/hcp/request-a-sales-rep/index.js" /* webpackChunkName: "component---src-pages-hcp-request-a-sales-rep-index-js" */),
  "component---src-pages-hcp-safety-copy-index-js": () => import("./../../../src/pages/hcp/safety copy/index.js" /* webpackChunkName: "component---src-pages-hcp-safety-copy-index-js" */),
  "component---src-pages-hcp-safety-tolerability-profile-index-js": () => import("./../../../src/pages/hcp/safety-tolerability-profile/index.js" /* webpackChunkName: "component---src-pages-hcp-safety-tolerability-profile-index-js" */),
  "component---src-pages-hcp-shares-copay-program-index-js": () => import("./../../../src/pages/hcp/shares-copay-program/index.js" /* webpackChunkName: "component---src-pages-hcp-shares-copay-program-index-js" */),
  "component---src-pages-hcp-sitemap-index-js": () => import("./../../../src/pages/hcp/sitemap/index.js" /* webpackChunkName: "component---src-pages-hcp-sitemap-index-js" */),
  "component---src-pages-hcp-support-center-and-savings-index-js": () => import("./../../../src/pages/hcp/support-center-and-savings/index.js" /* webpackChunkName: "component---src-pages-hcp-support-center-and-savings-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patients-5-good-reasons-to-ask-index-js": () => import("./../../../src/pages/patients/5-good-reasons-to-ask/index.js" /* webpackChunkName: "component---src-pages-patients-5-good-reasons-to-ask-index-js" */),
  "component---src-pages-patients-caregiver-resources-index-js": () => import("./../../../src/pages/patients/caregiver-resources/index.js" /* webpackChunkName: "component---src-pages-patients-caregiver-resources-index-js" */),
  "component---src-pages-patients-caregiver-signup-index-js": () => import("./../../../src/pages/patients/caregiver-signup/index.js" /* webpackChunkName: "component---src-pages-patients-caregiver-signup-index-js" */),
  "component---src-pages-patients-caregiver-survey-index-js": () => import("./../../../src/pages/patients/caregiver-survey/index.js" /* webpackChunkName: "component---src-pages-patients-caregiver-survey-index-js" */),
  "component---src-pages-patients-downloads-support-index-js": () => import("./../../../src/pages/patients/downloads-support/index.js" /* webpackChunkName: "component---src-pages-patients-downloads-support-index-js" */),
  "component---src-pages-patients-insertion-and-after-care-index-js": () => import("./../../../src/pages/patients/insertion-and-after-care/index.js" /* webpackChunkName: "component---src-pages-patients-insertion-and-after-care-index-js" */),
  "component---src-pages-patients-potential-side-effects-index-js": () => import("./../../../src/pages/patients/potential-side-effects/index.js" /* webpackChunkName: "component---src-pages-patients-potential-side-effects-index-js" */),
  "component---src-pages-patients-removal-and-reinsertion-index-js": () => import("./../../../src/pages/patients/removal-and-reinsertion/index.js" /* webpackChunkName: "component---src-pages-patients-removal-and-reinsertion-index-js" */),
  "component---src-pages-patients-shares-savings-program-index-js": () => import("./../../../src/pages/patients/shares-savings-program/index.js" /* webpackChunkName: "component---src-pages-patients-shares-savings-program-index-js" */),
  "component---src-pages-patients-sitemap-index-js": () => import("./../../../src/pages/patients/sitemap/index.js" /* webpackChunkName: "component---src-pages-patients-sitemap-index-js" */),
  "component---src-pages-patients-support-center-index-js": () => import("./../../../src/pages/patients/support-center/index.js" /* webpackChunkName: "component---src-pages-patients-support-center-index-js" */),
  "component---src-pages-patients-what-are-my-childs-treatment-options-index-js": () => import("./../../../src/pages/patients/what-are-my-childs-treatment-options/index.js" /* webpackChunkName: "component---src-pages-patients-what-are-my-childs-treatment-options-index-js" */),
  "component---src-pages-patients-what-is-central-precocious-puberty-index-js": () => import("./../../../src/pages/patients/what-is-central-precocious-puberty/index.js" /* webpackChunkName: "component---src-pages-patients-what-is-central-precocious-puberty-index-js" */),
  "component---src-pages-patients-what-is-supprelin-la-index-js": () => import("./../../../src/pages/patients/what-is-supprelin-la/index.js" /* webpackChunkName: "component---src-pages-patients-what-is-supprelin-la-index-js" */),
  "component---src-pages-patients-why-treat-central-precocious-puberty-index-js": () => import("./../../../src/pages/patients/why-treat-central-precocious-puberty/index.js" /* webpackChunkName: "component---src-pages-patients-why-treat-central-precocious-puberty-index-js" */)
}

